import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";

import LogoTop from "./images/logo-bianco-2-1.png";

export {LogoTop};

export const SERVICE_URL = "https://kalibra.azurewebsites.net/api"; //"http://localhost:3000/api";

const el = document.getElementById('root');

const root = ReactDOM.createRoot(el);

root.render(<App/>);
