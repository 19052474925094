import React from "react";
import  DeleteIcon from "@material-ui/icons/DeleteForever";
import { formatPrice } from "../utils/prices";

export function CarrelloCell(props) {

    const articolo = props.articolo;

    function getPorzioni(){
        let porzioni = "";
        if(articolo["NPORZ"] && articolo["NPORZ"] !== ""){
            porzioni = " | " + articolo["NPORZ"] + " porz. / ";
            if(articolo["GRPORZ"] && articolo["GRPORZ"] !== ""){
                porzioni = porzioni + articolo["GRPORZ"] + " g";
            } else if(articolo["MGPORZ"] && articolo["MGPORZ"] !== ""){
                porzioni = porzioni + articolo["MGPORZ"] + " mg"
            }
        }
        return porzioni;
    }

    return (
        <div>
            <div
                className="row"
                style={{
                    padding: "0px 24px",
                }}
            >
                <div
                    className="col-4"
                    style={{
                        marginTop:"12px",
                        textAlign:"center"
                    }}
                >
                    <img
                        src={require(`../images/best-sellers/${articolo.CODICE}.png`)}
                        alt=''
                        style={{

                            width:"110px",
                            display: "inline-block"
                        }}
                    />

                </div>
                <div
                    className="col-8"
                    style={{
                        marginTop:"12px",
                        textAlign:"left"
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"16px",
                            color: "#0F4651",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "left !important",
                            marginBottom: "0px"
                        }}
                    >

                        {articolo.NOME}
                        </p>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"14px",
                            color: "#68ABAD",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "left !important",
                            marginBottom: "0px"
                        }}
                    >
                        {formatPrice(articolo["PREZZOCONF"])}
                        <span
                            style={{
                                fontFamily: "Regular",
                                color: "#808080"
                            }}
                        >
                            &nbsp;&nbsp;{getPorzioni()}
                            {props.isRecap && ` | Codice Prodotto: ${articolo.CODICE}`}
                        </span>
                    </p>
                </div>
                <p className="col-12"
                   style={{
                       marginTop: '4px',
                       fontFamily: "Medium",
                       fontSize:"14px",
                       color: "#68ABAD",
                       display: "flex",
                       width: '100%',
                       justifyContent: "flex-end",
                       alignItems: "center",
                       marginBottom: "0px"
                   }}
                >
                    X {articolo.qty}

                    {!props.isRecap && <DeleteIcon
                        style={{
                            marginLeft: "20px",
                            width: "24px",
                            height: "24px",
                            color: "#808080"
                        }}
                    />}
                </p>
            </div>
            <div
                style={{
                    backgroundColor: "#F0F0F0",
                    height: "2px",
                    marginTop: "12px"
                }}
            />
        </div>
    )
}
