import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import { LogoTop } from "..";
import { useNavigate, useParams } from "react-router-dom";
import { CarrelloCell } from "../components/CarrelloCell";
import axios from "axios";
import { SERVICE_URL } from "..";


import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import it from 'date-fns/locale/it';
import { formatPrice, parsePrice } from "../utils/prices";

registerLocale('it', it);
setDefaultLocale("it");

export function RecapOrderView(props) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [carrello, setCarrello] = useState({});
    const [isCarta, setIsCarta] = useState(false);
    const [isContrassegno, setIsContrassegno] = useState(false);
    const flagCostoSpedizione = useMemo(() => carrello.articles
        ?
            carrello.articles
                .filter(product => product["CATEGORIA"] === "INTEGRATORI MICRONUTRIZIONALI")
                .reduce((total, product) => total + parsePrice(product["PREZZOCONF"] * parseInt(product.qty)), 0) < 85 &&
            carrello.articles.every((product) => product["CATEGORIA"] !== "COFANETTI INTEGRATORI")
        : false
    , [carrello])

    const {id} = useParams();

    const costoSpedizione = 8.80;

    useEffect(() => {
        window.scrollTo(0, 0);
        executeGetRecapOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const executeGetRecapOrder = () => {
        setIsLoading(true);

        if(!id) {
            setIsLoading(false);
            navigate("/errore");
            return;
        }

        axios.get( SERVICE_URL + '/order/' + id).then((response) => {
            if(response.data){
                setCarrello(response.data);
                if(response.data.payment === "CARTA"){
                    setIsCarta(true);
                    setIsContrassegno(false);
                } else {
                    setIsCarta(false);
                    setIsContrassegno(true);
                }
                setIsLoading(false);
            //   setArticoli(response.data.bestseller);
            //   //setUnitaMisure(response.data.unita_misura);
            //   setCategorie(response.data.categories);
            //   setUser(response.data.user);
            //   setIsLoading(false);

            }

        }).catch((error) => {
          console.error(error);
          setIsLoading(false);
          navigate("/errore");
        });
    }

    function calculateTot(){
        let currentArticles = carrello.articles || [];
        let tot = 0;
        currentArticles.forEach((elem) => {
            const price = parsePrice(elem["PREZZOCONF"])
            tot = tot + (price * parseInt(elem.qty));
        })
        if(flagCostoSpedizione){
            tot = tot + costoSpedizione
        }
        return formatPrice(tot)
    }

    function calculateTotProdotti(){
        let currentArticles = [...carrello.articles];
        let tot = 0;
        currentArticles.forEach((elem) => {
            const price = parsePrice(elem["PREZZOCONF"])
            tot = tot + (price * parseInt(elem.qty));
        })
        return formatPrice(tot)
    }

    return (
        <div
            className="container"
            style={{
                marginTop: "0px",
                position: "relative",
                padding: "0px"
            }}
        >
            <div
                style={{
                    display: `${isLoading ? "flex" : "none"}`,
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    zIndex: "100"
                }}
            >
                <dotlottie-player src="https://lottie.host/90a4cd86-6db1-4a26-a699-a36fd63d1fce/tEBIPFUn7o.json"
                                  mode="normal" background="transparent" speed="1"
                                  style={{width: "300px", height: "300px", display: "block"}} direction="1" loop
                                  autoplay></dotlottie-player>
            </div>

            <div
                className="row"
                style={{
                    padding: "0px 20px",
                    backgroundColor: "#0F4651",
                    height: "120px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <img
                    src={LogoTop}
                    alt=''
                    style={{
                        marginTop: "16px",
                        height: "32px",
                        width: 'auto',
                        display: "inline-block"
                    }}
                />
            </div>


            <div
                className="row"
                style={{
                    marginTop: "24px",
                    textAlign: "center",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "22px",
                        color: "#004750",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Riepilogo ordine
                </p>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "12px",
                    textAlign: "center",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Controlla il tuo ordine
                </p>

            </div>

            {carrello.articles && carrello.articles.map((articolo) => (
                <CarrelloCell key={articolo.CodArticolo} articolo={articolo} isRecap={true}/>
            ))}
            {flagCostoSpedizione && (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "baseline",
                            padding: "0px 24px",
                            width: "100%",
                            marginTop: "16px"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Regular",
                                color: "#808080",
                                fontSize:"14px",
                                display: "inline-block",
                                marginRight: "12px",
                                textAlign: "center !important",
                                marginBottom: "0px"
                            }}
                        >
                            Totale prodotti:
                        </p>
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize:"14px",
                                color: "#68ABAD",
                                display: "inline-block",

                                textAlign: "center !important",
                                marginBottom: "0px"
                            }}
                        >
                            {calculateTotProdotti()}
                        </p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "baseline",
                            padding: "0px 24px",
                            width: "100%",
                            marginTop: "4px"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Regular",
                                color: "#808080",
                                fontSize:"14px",
                                display: "inline-block",
                                marginRight: "12px",
                                textAlign: "center !important",
                                marginBottom: "0px"
                            }}
                        >
                            Costo spedizione:
                        </p>
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize:"14px",
                                color: "#68ABAD",
                                display: "inline-block",

                                textAlign: "center !important",
                                marginBottom: "0px"
                            }}
                        >
                            {formatPrice(costoSpedizione)}
                        </p>
                    </div>
                </>
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "baseline",
                    padding: "0px 24px",
                    width: "100%",
                    marginTop: "16px"
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "16px",
                        color: "#808080",
                        display: "inline-block",
                        marginRight: "12px",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Totale ordine:
                </p>
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize: "20px",
                        color: "#0F4651",
                        display: "inline-block",

                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    {calculateTot()}
                </p>
            </div>


            <div className="row">
                <div class="col-lg-6">
                    <div
                        style={{
                            marginTop: "48px",
                            padding: "0px 20px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize: "20px",
                                    color: "#68ABAD",
                                    display: "inline-block",
                                    width: "100%",
                                    marginBottom: "0px",
                                }}
                            >
                                Codici Inseriti
                            </p>

                        </div>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "0px",
                                marginBottom: "0px",
                            }}
                        >
                            Codici di riferimento
                        </p>
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "24px",
                                marginBottom: "4px",
                            }}
                        >
                            CODICE MEDICO*
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#0F4651",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "4px",
                                marginBottom: "4px",
                            }}
                        >
                            {carrello["doctor"] || "-"}
                        </p>

                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "12px",
                                marginBottom: "4px",
                            }}
                        >
                            CODICE DELLA PRESCRIZIONE*
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#0F4651",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "4px",
                                marginBottom: "4px",
                            }}
                        >
                            {carrello["code"] || "-"}
                        </p>

                    </div>
                </div>
                <div class="col-lg-6">
                    <div style={{
                        marginTop: "48px",
                        padding: "0px 20px",
                    }}>
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "20px",
                                color: "#68ABAD",
                                display: "inline-block",
                                width: "100%",
                                marginBottom: "0px",
                            }}
                        >
                            Modalità pagamento
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "0px",
                                marginBottom: "0px",
                            }}
                        >
                            Modalità di pagamento desiderata:
                        </p>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "24px"
                                    }}
                                >
                                    <input disabled type="radio" id="carta" name="pagamento" value="carta"
                                           checked={isCarta}
                                           style={{
                                               width: "24px",
                                               height: "24px",
                                               marginRight: "24px",
                                               accentColor: "rgb(15, 70, 81)",
                                           }}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <label htmlFor="carta"
                                               style={{
                                                   fontFamily: "Medium",
                                                   fontSize: "16px",
                                                   color: "#0F4651",
                                                   marginBottom: "0px"
                                               }}
                                        >
                                            CARTA
                                        </label>
                                        <label htmlFor="carta"
                                               style={{
                                                   fontFamily: "Regular",
                                                   fontSize: "14px",
                                                   color: "#979797",
                                                   marginTop: "0px"
                                               }}
                                        >
                                            Riceverai il link per il pagamento
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "24px"
                                    }}
                                >
                                    <input disabled type="radio" id="contrassegno" name="pagamento" value="contrassegno"
                                           checked={isContrassegno}
                                           style={{
                                               width: "24px",
                                               height: "24px",
                                               marginRight: "24px",
                                               accentColor: "rgb(15, 70, 81)",
                                           }}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <label htmlFor="contrassegno"
                                               style={{
                                                   fontFamily: "Medium",
                                                   fontSize: "16px",
                                                   color: "#0F4651",
                                                   marginBottom: "0px"
                                               }}
                                        >
                                            CONTRASSEGNO
                                        </label>
                                        <label htmlFor="contrassegno"
                                               style={{
                                                   fontFamily: "Regular",
                                                   fontSize: "14px",
                                                   color: "#979797",
                                                   marginTop: "0px"
                                               }}
                                        >
                                            Pagherai alla consegna
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div className="row" style={{"padding-bottom":100}}>
                <div class="col-lg-6">
                    <div style={{
                        marginTop: "56px",
                        padding: "0px 20px",
                    }}>
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "20px",
                                color: "#68ABAD",
                                display: "inline-block",
                                width: "100%",
                                marginBottom: "0px",
                            }}
                        >
                            Dati di fatturazione
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "0px",
                                marginBottom: "0px",
                            }}
                        >
                            Dati personali per la fatturazione
                        </p>
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "24px",
                                marginBottom: "4px",
                            }}
                        >
                            NOME*
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#0F4651",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "4px",
                                marginBottom: "4px",
                            }}
                        >
                            {carrello.billing ? carrello.billing["Nome Cliente"] : "-"}
                        </p>


                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "24px",
                                marginBottom: "4px",
                            }}
                        >
                            COGNOME*
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#0F4651",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "4px",
                                marginBottom: "4px",
                            }}
                        >
                            {carrello.billing ? carrello.billing["Cognome Cliente"] : "-"}
                        </p>


                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "24px",
                                marginBottom: "4px",
                            }}
                        >
                            INDIRIZZO*&nbsp;
                            <span
                                style={{
                                    fontFamily: "Regular"
                                }}
                            >
                        &#10088;via, civico, città, provincia&#10089;
                    </span>
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#0F4651",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "4px",
                                marginBottom: "4px",
                            }}
                        >
                            {carrello.billing ? carrello.billing["Indirizzo"] : "-"}
                        </p>

                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "24px",
                                marginBottom: "4px",
                            }}
                        >
                            CODICE FISCALE*
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#0F4651",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "4px",
                                marginBottom: "4px",
                            }}
                        >
                            {carrello.billing ? carrello.billing["CodFiscale"] : "-"}
                        </p>

                    </div>
                </div>
                <div class="col-lg-6">
                    <div
                        style={{
                            marginTop: "48px",
                            marginBottom: "48px",
                            padding: "0px 20px",
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "20px",
                                color: "#68ABAD",
                                display: "inline-block",
                                width: "100%",
                                marginBottom: "0px",
                            }}
                        >
                            Dati di spedizione
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "0px",
                                marginBottom: "0px",
                            }}
                        >
                            Dati personali per la spedizione
                        </p>
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "24px",
                                marginBottom: "4px",
                            }}
                        >
                            NOME*
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#0F4651",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "4px",
                                marginBottom: "4px",
                            }}
                        >
                            {carrello.shipping ? carrello.shipping["Nome Cliente"] : "-"}
                        </p>


                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "24px",
                                marginBottom: "4px",
                            }}
                        >
                            COGNOME*
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#0F4651",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "4px",
                                marginBottom: "4px",
                            }}
                        >
                            {carrello.shipping ? carrello.shipping["Cognome Cliente"] : "-"}
                        </p>


                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "24px",
                                marginBottom: "4px",
                            }}
                        >
                            INDIRIZZO*&nbsp;
                            <span
                                style={{
                                    fontFamily: "Regular"
                                }}
                            >
                        &#10088;via, civico, città, provincia&#10089;
                    </span>
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#0F4651",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "4px",
                                marginBottom: "4px",
                            }}
                        >
                            {carrello.shipping ? carrello.shipping["Indirizzo"] : "-"}
                        </p>


                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize: "12px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "24px",
                                marginBottom: "4px",
                            }}
                        >
                            Email*
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize: "16px",
                                color: "#0F4651",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "4px",
                                marginBottom: "4px",
                            }}
                        >
                            {carrello.billing ? carrello.billing["Email"] : "-"}
                        </p>

                    </div>
                </div>
            </div>


        </div>
    )

}
